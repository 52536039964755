<template>
    <header
        class="header"
        :data-has-image="!!image"
        :data-pending="placeholder"
    >
        <div v-if="$slots.back">
            <slot name="back" />
        </div>

        <template v-if="image">
            <img
                :src="image.large"
                :srcset="getSrcSet(image, 'header')"
                sizes="100vw"
                alt=""
                class="header__background"
            >
        </template>

        <template v-else-if="placeholder">
            <content-placeholders-img class="header__background" />
        </template>

        <div
            v-if="$slots.default"
            class="header__title"
        >
            <slot />
        </div>
    </header>
</template>

<script setup>
import useSrcSets from '~/utils/srcset';

const { getSrcSet } = useSrcSets();

defineProps({
    image: {
        type: Object,
        required: false,
        default: null
    },
    placeholder: {
        type: Boolean,
        required: false,
        default: false
    }
});
</script>

<style lang="less">
.header {
    .grid-bleed;
    width: 100%;
}

.header:not([data-has-image='true']):not([data-pending='true']) {
    .grid-container;
    grid-template-rows: 1fr;

    padding: var(--spacing-xl) 0;

    background: var(--color-gray-200);

    @media @q-lg-min {
        padding: 4rem 0;
        min-height: 12rem;
    }
}

.header__background {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.header__title {
    align-self: end;
    padding-top: 1rem;

    @media @q-lg-min {
        padding-top: 1.5rem;
    }
}

.header[data-pending='true'],
.header[data-has-image='true'] {
    height: auto;
    aspect-ratio: 1/.2222;
}

.header--narrow:not([data-has-image='true']):not([data-pending='true']) {
    .grid-container(true, var(--grid-maxWidth-content));
}
</style>
